@if (!link) {
  <div>
    <mat-card-subtitle>
      <h4 style="margin-top: 12px;">
        Pay with VISA/MasterCard
      </h4>
    </mat-card-subtitle>
    @if (!requesting) {
      <button (click)="onPayment()" class="full-width" mat-flat-button color="accent"
        type="submit">
        Initiate Payment for {{storedCart().currency}} {{amount() | number}}
      </button>
    }
    @if (requesting) {
      <button disabled class="full-width" mat-flat-button>
        wait <i class="fas fa-circle-notch fa-spin"></i>
      </button>
    }
    <!-- <a class="powered" href="https://suite.jjengo.com" target="_blank">
    <img src="../../../assets/powered.svg">
  </a> -->
</div>
}
@if (link) {
  <div style="padding: 12px;">
    <a [href]="link" target="_blank" class="full-width" mat-flat-button color="accent">
      Complete Card Payment for {{storedCart().currency}} {{amount() | number}}
    </a>
  </div>
}