<mat-card appearance="outlined" style="margin-bottom: 4px;">
    <div class="ticket-container">
        <div class="icon">
            <img src="../../../assets/kit.png" width="65px">
        </div>
        <div class="details">
            <mat-card-subtitle>
                <h3 style="margin: 0;">
                    <b>SIZE:</b> {{kit().sname}}
                </h3>
            </mat-card-subtitle>
            <span style="display: flex;">
                <button (click)="subtract()" mat-icon-button color="warn" style="padding: 0 !important; height: 32px !important; width: 32px !important;">
                    <span class="material-symbols-sharp ico">remove</span>
                </button>
                <span style="line-height: 32px; font-size: 1.2em; margin: auto 12px;">{{kit().count}} kits</span>
                <button (click)="add()" mat-icon-button color="primary" style="padding: 0 !important; height: 32px !important; width: 32px !important;">
                    <span class="material-symbols-sharp ico">add</span>
                </button>
            </span>
        </div>
        <div class="action">
            <button (click)="delete()" mat-icon-button color="warn">
                <span class="material-symbols-sharp">
                    delete
                </span>
            </button>
        </div>
    </div>
</mat-card>