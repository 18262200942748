import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CheckoutRequest, CheckoutResponse, ClubFilter, DonorObject, Edition, EditionCart, EditionClub, EditionKit, EditionTicket, MakeCart, MyKit } from '../models/ticketing.models';
import { rcpuBaseUrl, runEdition } from '../global/variables';
import { Observable } from 'rxjs/internal/Observable';
import { ClubLeaderboard } from '../models/leaderboard.models';

@Injectable({
    providedIn: 'root'
})
export class TicketingService {

    #http = inject(HttpClient)

    getEdition(): Observable<Edition> {
        return this.#http.get<Edition>(`${rcpuBaseUrl}editions/${runEdition}/`)
    }

    getClubs(filter: ClubFilter): Observable<EditionClub[]> {
        return this.#http.post<EditionClub[]>(`${rcpuBaseUrl}clubs/filtered/`, filter)
    }

    getLeaderboard(page: number): Observable<ClubLeaderboard> {
        return this.#http.get<ClubLeaderboard>(`${rcpuBaseUrl}clubs/leaderboard/?edition=${runEdition}&page=${page}`)
    }

    getEditionTickets(): Observable<EditionTicket[]> {
        return this.#http.get<EditionTicket[]>(`${rcpuBaseUrl}editions/${runEdition}/tickets/`)
    }

    getEditionKits(): Observable<EditionKit[]> {
        return this.#http.get<EditionKit[]>(`${rcpuBaseUrl}kits/available/?edition=${runEdition}`)
    }

    submitCart(payload: MakeCart): Observable<EditionCart> {
        return this.#http.post<EditionCart>(`${rcpuBaseUrl}editions/${runEdition}/cart/`, payload)
    }

    authenticatedDonation(payload: DonorObject): Observable<any> {
        return this.#http.post<any>(`${rcpuBaseUrl}editions/${runEdition}/authenticated-donation/`, payload)
    }

    unAuthenticatedDonation(payload: DonorObject): Observable<any> {
        return this.#http.post<any>(`${rcpuBaseUrl}editions/${runEdition}/unauthenticated-donation/`, payload)
    }

    getCart(id: number): Observable<EditionCart> {
        return this.#http.get<EditionCart>(`${rcpuBaseUrl}carts/${id}/`)
    }

    checkoutCart(id: number, payload: CheckoutRequest): Observable<CheckoutResponse> {
        return this.#http.post<CheckoutResponse>(`${rcpuBaseUrl}carts/${id}/checkout/`, payload)
    }

    purchased(): Observable<MyKit[]> {
        return this.#http.get<MyKit[]>(`${rcpuBaseUrl}carts/mine/?edition=${runEdition}`)
    }
}
