<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <!-- <mat-toolbar  class="left-bar" color="primary">
    <span>Cancer Run</span>
    <span *ngIf="isHandset$ | async" >&nbsp; Menu</span>
  </mat-toolbar> -->
  <img class="menu-logo" src="https://cdn.jjengo.com/rcpu/rcr2024-color.png" alt="logo">
  <mat-nav-list>
    @for (item of menu; track item) {
      <a mat-list-item routerLinkActive="active" [routerLink]="item.route">
        <span style="display: flex;">
          <span class="material-symbols-sharp menu-icon">{{item.icon}}</span>
          <span class="menu-item">{{item.title}}</span>
        </span>
      </a>
    }
    @if (loggedIn) {
      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout()">
        <span style="display: flex;">
          <span class="material-symbols-sharp menu-icon" style="color: brown;">logout</span>
          <span class="menu-item" style="color: brown;">Sign Out</span>
        </span>
      </a>
    }

  </mat-nav-list>
</mat-sidenav>
<mat-sidenav-content>
  @if (isHandset$ | async) {
    <mat-toolbar color="primary" class="primary-bar">
      @if (isHandset$ | async) {
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          >
          <span class="material-symbols-sharp">menu</span>
        </button>
      }
      <span>
        <img style="height: 45px;" src="https://cdn.jjengo.com/rcpu/rcr2024-white.png" alt="">
      </span>
    </mat-toolbar>
  }
  <!-- Add Content Here -->
  <section class="final-content">
    <ng-content></ng-content>
  </section>
</mat-sidenav-content>
</mat-sidenav-container>