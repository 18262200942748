import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UgTelService {

    ugTel(msisdn: string): string | undefined {
        const regex = /^\+?(256|0)?(31|39|70|75|74|76|77|78)(\d{7})$/;
        const match = regex.exec(msisdn);
        let canon: string | undefined = undefined;

        if (match) {
            canon = `256${match[2]}${match[3]}`;
        }
        return canon;
    }
}
