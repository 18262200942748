let baseUrl = "https://api.tujaguze.com/"; 
// let baseUrl = "https://tapi.tujaguze.com/"; 
// let baseUrl = "http://172.20.10.3:8000/"
// let baseUrl = "http://localhost:8000/"


export var authBaseUrl = `${baseUrl}authx/`;
export var rcpuBaseUrl = `${baseUrl}rcpu/`;

export const runEdition = 2
export const editionTicketPrice = 30000

export const ssrPort = 4072 //4073 too

export const siteDetails = {
    "description":"We invite you to join us for the 13th Rotary Cancer Run raising money to create awareness and access to cheap and better medical care. This is a great opportunity to show support for those who have and are battling cancer and to honor the memory of those who have lost their lives to this terrible disease",
    "large_img":"https://cdn.jjengo.com/rcpu-header-2024.png",
    "small_img":"https://cdn.jjengo.com/rcpu-header-2024.png",
    "image_alt":"Rotary Cancer Run",
    "handle":"@UgRotaryCancerP",
    "url":"https://www.rotaryugandacancer.org"
}