import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    #snackbar = inject(MatSnackBar)

    public errorSnackbar(message: string) {
        this.#snackbar.open(message, "OKAY", {
            duration: 4000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'bottom'
        })
    }

    public successSnackbar(message: string) {
        this.#snackbar.open(message, "OKAY", {
            duration: 4000,
            panelClass: ['success-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'bottom'
        })
    }
}
