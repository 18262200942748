import { Component, input, output } from '@angular/core';
import { CartItem } from '../../models/ticketing.models';

@Component({
  selector: 'app-kit-size',
  templateUrl: './kit-size.component.html',
  styleUrls: ['./kit-size.component.scss']
})
export class KitSizeComponent {
    kit = input.required<CartItem>()
    changeItemEvent = output<CartItem>()
    removeItemEvent = output<CartItem>()

    add(){
        const oldVal = +this.kit().count
        this.kit().count = oldVal + 1
        this.changeItemEvent.emit(this.kit())
    }
    subtract(){
        const oldVal = +this.kit().count
        if(oldVal > 1){
            this.kit().count = oldVal - 1
        this.changeItemEvent.emit(this.kit())
        }
    }

    delete(){
        this.removeItemEvent.emit(this.kit())
    }
}
