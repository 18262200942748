import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { EditionTicket, EditionKit, CartItem } from '../../models/ticketing.models';

@Component({
    selector: 'app-ticket-stub',
    templateUrl: './ticket-stub.component.html',
    styleUrls: ['./ticket-stub.component.scss']
})
export class TicketStubComponent implements OnInit {

    ticketForm?: FormGroup
    ticketsAvailable = true

    #dialogRef = inject(MatDialogRef<TicketStubComponent>)
    #formBuilder = inject(FormBuilder)
    data = inject<{ tickets: EditionTicket[], kits: EditionKit[] }>(MAT_DIALOG_DATA)

    ngOnInit(): void {
        this.#buildForm()
    }

    onSubmit() {
        if (this.ticketForm?.valid) {
            const item: CartItem = {
                ticket: this.ticketForm.value.ticket.id,
                size: this.ticketForm.value.size.id,
                count: this.ticketForm.value.count,
                sname: this.ticketForm.value.size.size
            }
            this.#dialogRef.close(item)
        }
    }

    kitChange(e: MatSelectChange) {
        const kit = e.value as EditionKit
        console.log(kit)
        if (kit.available <= 0 || !kit.is_active) {
            this.ticketsAvailable = false
        } else {
            this.ticketsAvailable = true
        }
    }

    #buildForm() {
        this.ticketForm = this.#formBuilder.group({
            ticket: [this.data.tickets[0], Validators.required],
            size: ['', Validators.required],
            count: ['1', [Validators.required, Validators.min(1)]]
        })
    }
}
