@if (!message) {
  <div>
    <mat-card-subtitle>
      <h4 style="margin-top: 12px;">
        🇺🇬 Pay with MTN/Airtel Uganda
      </h4>
    </mat-card-subtitle>
    @if (paymentForm) {
      <form [formGroup]="paymentForm" (ngSubmit)="onPayment()">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Telephone Number (MTN/Airtel UG)</mat-label>
          <input type="number" matInput placeholder="e.g. 256777000999" formControlName="fsource">
          @if (paymentForm.controls['fsource'].errors?.['required']) {
            <mat-error>
              Please enter MTN/Airtel Uganda Phone number
            </mat-error>
          }
        </mat-form-field>
        @if (!requesting) {
          <button [disabled]="paymentForm.invalid" class="full-width" mat-flat-button color="accent"
            type="submit">
            Initiate Payment for {{storedCart().currency}} {{amount() | number}}
          </button>
        }
        @if (requesting) {
          <button disabled class="full-width" mat-flat-button>
            wait <i class="fas fa-circle-notch fa-spin"></i>
          </button>
        }
      </form>
    }
    <!-- <a class="powered" href="https://suite.jjengo.com" target="_blank">
    <img src="../../../assets/powered.svg">
  </a> -->
</div>
}

@if (message) {
  <div style="padding: 4px;">
    <mat-card style="background-color: rgb(101, 187, 15); color: white; padding: 12px;">
      {{message}}
    </mat-card>
  </div>
}