import { isPlatformBrowser } from '@angular/common';
import { Component, inject, input, output, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MakeCart } from '../../models/ticketing.models';
import { TicketingService } from '../../services/ticketing.service';
import { SnackbarService } from '../snackbar.service';
import { UgTelService } from '../ug-tel.service';
@Component({
    selector: 'app-momo',
    templateUrl: './momo.component.html',
    styleUrls: ['./momo.component.scss']
})
export class MomoComponent {
    amount = input.required<number>()
    storedCart = input.required<MakeCart>()
    completed = output<number>()
    paymentForm?: FormGroup
    requesting = false;
    message?: string;

    // injections
    #formBuilder = inject(FormBuilder) 
    #service = inject(TicketingService) 
    #snackBar = inject(SnackbarService) 
    #momo = inject(UgTelService)

    ngOnInit(): void {
        this.#buildForm()
    }

    onPayment() {
        if (this.paymentForm?.valid) {
            let payload = {
                "fsource": this.#momo.ugTel(this.paymentForm.value.fsource as string)
            }
            this.requesting = true
            this.#service.submitCart(this.storedCart()).subscribe({
                next: data => {
                    this.requesting = true;
                    this.#service.checkoutCart(data.id, payload).subscribe({
                        next: () => {
                            this.message = 'Respond to Prompt on your phone to complete payment.'
                            setTimeout(() => {
                                this.completed.emit(data.id)
                            }, 10000)
                        },
                        error: (e) => {
                            this.#snackBar.errorSnackbar(e.error.error)
                        }
                    })
                }
            })

        }
    }

    #buildForm() {
        this.paymentForm = this.#formBuilder.group({
            fsource: ['', Validators.required]
        })
    }
}
