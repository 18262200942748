<mat-card>
  <mat-card-content>
    <mat-card-title>
      <h2 style="text-align: center;">
        ADD KIT(s) to cart
      </h2>
    </mat-card-title>
    @if (ticketForm) {
      <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
        <!-- <div class="ticket">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Ticket</mat-label>
          <mat-select formControlName="ticket">
            <mat-option *ngFor="let s of data.tickets" [value]="s">{{s.currency}}{{s.amount | number}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="ticket">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Kit Size</mat-label>
          <mat-select formControlName="size" (selectionChange)="kitChange($event)">
            @for (s of data.kits; track s) {
              <mat-option [value]="s">{{s.size}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="count">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Kits</mat-label>
          <input matInput type="number" placeholder="e.g. 1" formControlName="count">
        </mat-form-field>
      </div>
      <button class="full-width" [disabled]="ticketForm.invalid || !ticketsAvailable"  mat-button color="primary" type="submit">Add Ticket(s)</button>
      @if (!ticketsAvailable) {
        This ticket size is sold out.
      }
    </form>
  }
</mat-card-content>
</mat-card>