import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { RcpuMenu } from '../../models/sell.models';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';

@Component({
    selector: 'rcr-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit{
    role = ""
    menu: RcpuMenu[] = []
    loggedIn = false

    #breakpointObserver = inject(BreakpointObserver)
    #router = inject(Router)
    #platformId = inject(PLATFORM_ID)

    isHandset$: Observable<boolean> = this.#breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    ngOnInit(): void {
        if (isPlatformBrowser(this.#platformId)) {
            this.#setMenu()
            const accessToken = localStorage.getItem("a")
            if(accessToken){
                this.loggedIn = true
            } else {
                this.loggedIn = false
            }
        }
    }

    logout() {
        if (isPlatformBrowser(this.#platformId)) {
            localStorage.clear()
            localStorage.setItem('redir', '/')
            this.#router.navigate(['/'])
        }
    }

    #setMenu() {
        this.menu = [
            {
                icon: "shopping_bag",
                title: "Register to Run",
                route: "/buy/get-kits"
            },
            {
                icon: "redeem",
                title: "Make Donation",
                route: "/donations"
            },
            {
                icon: "account_balance",
                title: "Corporate Breakfast",
                route: "/corporate/breakfast"
            },
            {
                icon: "social_leaderboard",
                title: "Leaderboard",
                route: "/leaderboard"
            },
            {
                icon:'qr_code',
                title:'My Unpicked Kits',
                route: '/purchased'
            }
        ]
    }

}
