import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, inject, Inject, input, Input, output, Output, PLATFORM_ID } from '@angular/core';
import { MakeCart, CheckoutRequest } from '../../models/ticketing.models';
import { TicketingService } from '../../services/ticketing.service';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {
    amount = input.required<number>()
    storedCart = input.required<MakeCart>()
    completed = output<number>()
    requesting = false;
    link?: string;

    #service = inject(TicketingService) 

    onPayment() {
        let payload: CheckoutRequest = {}
        this.requesting = true;
        this.#service.submitCart(this.storedCart()).subscribe({
            next: data => {
                this.#service.checkoutCart(data.id, payload).subscribe({
                    next: d => {
                        this.requesting = false;
                        this.link = d.link;
                        setTimeout(() => {
                            this.completed.emit(data.id)
                        }, 15000)
                    }
                })
            }
        })

    }

    toggleLink() {
        this.link = undefined;
        location.reload();
    }

}
